<style scoped>
.content {
  border-bottom: 1px solid var(--borderColor);
  position: relative;
  padding: 5px 0 5px;
  text-align: left;
}
.mar-rg10 {
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>

<template>
  <div>
    <div style="padding: 0 10px; margin-top: 10px">
      <div class="content" v-for="(data, index) in listCon" :key="index">
        <rn-list :contentData="data"></rn-list>

        <div style="display: flex; flex-wrap: wrap">
          <Button
            type="info"
            @click="toUrl(`/messageDetail?id=${data._cid}`)"
            class="mar-rg10"
            >预览</Button
          >
          <Button
            type="info"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '列表'"
            >编辑</Button
          >
          <Button
            type="info"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '审核'"
            >通过</Button
          >
          <Button
            type="error"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '审核'"
            >不通过</Button
          >
          <Button
            type="error"
            v-if="isShow == '列表'"
            @click="deleteArticle(data.id)"
            >删除</Button
          >
          <Button
            type="info"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '发布'"
            >发布</Button
          >
          <Button
            type="info"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '发布'"
            >传达</Button
          >
          <Button
            type="info"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '发布'"
            >分享</Button
          >
          <Button
            type="info"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '发布'"
            >置顶</Button
          >
          <Button
            type="info"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '发布'"
            >轮播</Button
          >
          <Button
            type="info"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '发布'"
            >水印</Button
          >
          <Button
            type="info"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '发布'"
            >公告</Button
          >
          <Button
            type="info"
            @click="edit(data._cid)"
            class="mar-rg10"
            v-if="isShow == '发布'"
            >设置关联报道</Button
          >
        </div>
      </div>
    </div>
    <load-more
      @refresh="getList"
      :load-status="isLoadMore"
      :isShow="loadMoreShow"
    ></load-more>
    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
/* eslint-disable  */
import debug from "debug";
import Vue from "vue";
import moment from "moment";
import { wxConPost, getUserPm } from "../../api/httpApi";
import _ from "lodash";

import loadMore from "../../components/loadMoreData.vue";
import rnList from "../../components/rnContentList.vue";
import noPassCom from "../../components/noPassCom.vue";
const _d = debug("@pages:contentList");

moment.locale("zh-cn");

export default {
  name: "contentList",
  data() {
    return {
      isUserPm: true,
      listCon: [],
      isLoadMore: true,
      loadMoreShow: false,
      nowType: "",
      nowReview: "",
      nowIsJing: "",
      isShow: "",
    };
  },

  methods: {
    getReviewStatus(review) {
      switch (review) {
        case "0":
          return "待审核";
        case "1":
          return "审核通过";
        case "-1":
          return "未通过";
        case "2":
          return "草稿";
        default:
          return "草稿";
      }
    },
    edit(cid) {
      this.$router.push({
        path: "contentEditor",
        query: { cid: cid },
      });
    },
    toUrl(url) {
      this.$router.push({
        path: url,
      });
    },
    formatDate(data) {
      return moment(new Date(data)).format("YYYY-MM-DD HH:mm");
    },
    deleteArticle() {},
    async getList() {
      let query = {
        limit: 20,
        skip: this.listCon.length,
        type: this.nowType,
      };
      if (this.nowReview !== "all") {
        query.review = this.nowReview;
      }
      if (this.nowIsJing !== "all") {
        query.isJingxuan = this.nowIsJing;
      }
      try {
        console.log("条件");
        console.log(query);
        const ret = await wxConPost(
          "/services/gttt-content-mgt-get/wxContent/getArticle",
          query
        );
        const dataList = [];
        const data = ret.value;
        if (data && data.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          let listData = [];
          for (const item of data) {
            let columntype = "text";
            if (item.video) {
              columntype = "video";
            } else if (item.img.length > 0) {
              columntype = "image";
              listData = item;
              Object.assign(listData, {
                type: columntype,
                keywordsStr: item.keywords ? item.keywords.join(" ") : "",
              });
              dataList.push(listData);
            }
          }
          this.listCon = this.listCon.concat(dataList);
          // 显示加载
          this.loadMoreShow = true;
          // 显示加载
          this.isLoadMore = true;
        }
      } catch (error) {}
    },
  },
  async mounted() {
    const userPm = getUserPm("稿件管理");
    if (userPm) {
      if (userPm && userPm.pmSuper) {
        this.nowType = this.$route.query.type;
        this.nowReview = this.$route.query.review;
        this.nowIsJing = this.$route.query.isJingxuan;
        this.isShow = this.$route.query.isShow;
        this.getList();
      } else {
        this.isUserPm = false;
      }
    } else {
      this.isUserPm = false;
    }
  },
  beforeCreate() {
    document.title = "内容列表";
  },
  components: {
    loadMore,
    rnList,
    noPassCom,
  },
};
</script>
